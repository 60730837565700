<template>
  <div class="contain">
    <el-tabs :before-leave="leaveTab" class="el-tabs" v-model="path" v-if="path">
      <el-tab-pane
        v-for="item in tabItems"
        :key="item.name"
        :label="item.name"
        :name="item.path"
      ></el-tab-pane>
    </el-tabs>
    <router-view />
  </div>
</template>
<script>
import bg from '@/mixin/background'

export default {
  name: 'statisticalAnalysis',

  mixins: [bg],

  data() {
    return {
      path: '',
    }
  },

  computed: {
    tabItems() {
      return [
        {
          name: '统计概况',
          path: '/statisticalAnalysis/overview',
          show: this.$store.state.roots.includes(70),
        },
        {
          name: '订单分析',
          path: '/statisticalAnalysis/order',
          show: this.$store.state.roots.includes(71),
        },
        {
          name: '流量分析',
          path: '/statisticalAnalysis/flow',
          show: this.$store.state.roots.includes(72),
        },
        {
          name: '学员分析',
          path: '/statisticalAnalysis/student',
          show: this.$store.state.roots.includes(73),
        },
        {
          name: '课程分析',
          path: '/statisticalAnalysis/course',
          show: this.$store.state.roots.includes(74),
        },
        {
          name: '上课分析',
          path: '/statisticalAnalysis/classIn',
          show: this.$store.state.roots.includes(75),
        },
        {
          name: '营销分析',
          path: '/statisticalAnalysis/marketing',
          show: this.$store.state.roots.includes(76),
        },
      ].filter((item) => item.show)
    },
  },

  methods: {
    leaveTab(activeName) {
      if (
        this.$store.getters.allMenuList.some(
          (item) => item.status == 2 && item.path == activeName
        )
      ) {
        this.$root.toggleProductUpdate()
        return false
      }
    },
  },

  watch: {
    path(path) {
      //判断端口中传过来的path是否在tabItems中存在
      if (path && this.tabItems.some((item) => item.path == path)) {
        // 跳转路由
        this.$router.push({ path })
      }
    },

    '$store.state.currRouterPage': {
      handler(value) {
        this.path = value.path
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.contain {
  margin: 20px;
}
.el-tabs {
  padding-left: 20px;
  background: #fff;
}
::v-deep .el-tabs .el-tabs__nav-wrap::after {
  background: transparent;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.04);
}
::v-deep .el-tabs__header {
  margin: 0;
}
</style>
